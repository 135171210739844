import "../output.css";
import ServiceCard from "./ServiceCard";
import LeftFullServiceItem from "./FullServiceItems/LeftFullServiceItem";
import truckImg from "./images/truckpic.png";
import blackTruck from "./images/blacktruck.svg";
function Home() {
  return (
    <main className="flex flex-col w-screen">
      <div class="hero">
        <div class="w-full relative shadow-2xl w-full">
          <div class="absolute z-20 flex flex-col items-center w-full justify-center h-full font-serif ">
            <div class="text-6xl text-center text-white font-bold md:text-8xl selection:text-white">
              Noah Logistics
            </div>
            <div class=" text-3xl text-white md:text-4xl mt-2 font-medium text-center selection:text-white">
              Proudly serving the continental <br />
              U.S for over 20 years
            </div>
          </div>
          <img
            class="h-[36rem] w-full object-cover object-left-bottom"
            src={truckImg}
            alt="stock truck"
          ></img>
        </div>
        <div class="services pt-16 lg:pt-0 pb-6">
          <div class="min-h-screen bg-white flex justify-center items-center">
            <div class="container mx-auto p-12 bg-background rounded-xl shadow-2xl">
              <p class="text-[3rem] border-b-4 border-primary">Services</p>
              <br />
              <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 space-y-4 sm:space-y-0 place-items-center">
                <ServiceCard
                  imagesrc="https://www.mstruckinginc.com/img/services/crossdocking4.jpg"
                  title="Cross Docking"
                  details="Cross docking helps expedite the shipping process of your product. Instead of going straight from the railway to the warehouse, cross docking allows products to be consolidated at a distributor location, lowering or eliminating storage costs. This also allows products to be sorted more reliably and shipped more efficiently, creating more full truck loads to save on shipping costs."
                ></ServiceCard>
                <ServiceCard
                  imagesrc="https://www.txintlfreight.com/wp-content/uploads/2017/02/forklift-loading-freight-shipping-truck.png"
                  title="Load Reworking"
                  details="Having load issues? We can help fix that. Our load reworking service will allow you to sit back while we reorganize and rectify your shipment. We can deal with overweight loads by unloading and rearranging loads to lower weights. We can even replace your broken pallets, having your shipment back on the road and delivered in no time!"
                ></ServiceCard>
                <ServiceCard
                  imagesrc="https://www.warehousingandfulfillment.com/wp-content/uploads/2021/09/pallet-storage-pricing.jpg"
                  title="Short Term Storage"
                  details="You may need short-term storage for a variety of reasons. If you've missed a shipping appointment, we can hold your load during rescheduling of the shipment. If a load is shipped ahead of schedule, we can hold it until a truck is available to deliver it. Regardless of the need, we have daily, weekly, and monthly short-term storage available at an affordable cost."
                ></ServiceCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
