import "../../output.css";
function LeftFullServiceItem(props) {
  return (
    <div
      class={
        props.float == "true"
          ? "bg-white shadow-lg hover:shadow-xl transform transition duration-500 hover:scale-105"
          : ""
      }
    >
      <div class="pb-20 max-w-5xl ">
        <a id={props.title}>
          <div class="container mx-auto p-12 bg-background rounded-xl  shadow-2xl">
            <p class="text-[3rem] border-b-4 border-primary">{props.title}</p>
            <br />
            <div class="flex flex-col md:flex-row justify-between items-center">
              <div class="md:w-2/5">
                <img class="" src={props.imgSrc} />
              </div>
              <div class="md:w-1/2 border">
                <p>{props.details}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default LeftFullServiceItem;
