function Footer() {
  return (
    <footer class="fixed inset-x-0 bottom-0 h-10 bg-primary z-40">
      <div class="grid grid-cols-2 justify-center pt-[0.13rem] space-x-5 sm:static sm:inset-auto sm:pr-0 md:grid-cols-3">
        <div class="px-3 py-2 text-center">
          <a
            href="mailto:noahlogistic@gmail.com"
            class="text-sm font-medium text-white text-center hover:border-b-2 hover:border-background hover:text-background"
          >
            noahlogistic@gmail.com
          </a>
        </div>
        <div class="px-3 py-2 text-center">
          <a
            href="tel:9728004989"
            class="text-sm font-medium text-center text-white hover:border-b-2 hover:border-background hover:text-background"
          >
            972-800-4989
          </a>
        </div>
        <div class="px-3 py-2 hidden md:block text-center">
          <a
            // href="/loads"
            class="text-sm font-medium text-white hover:border-b-2 hover:border-background hover:text-background"
          >
            Management Tool
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
