import { HashRouter as Router } from "react-router-dom";
import { useState } from "react";
import Logo from "./images/trucklogo.svg";

function Nav() {
  const [isOpen, setisOpen] = useState(false);
  function handleClick() {
    setisOpen(!isOpen);
  }
  console.log(window.location.pathname);

  return (
    // TODO:
    // Center items in mobile mode nav
    // <Router >
    <Router>
      <nav className="flex items-center justify-between w-screen h-16 md:justify-around bg-primary">
        <div className="relative flex items-center justify-between h-16 lg:space-x-96 bg-primary">
          <a className="text-right" href="#">
            <img
              src={Logo}
              className={`mobile ${isOpen ? "absolute top-3" : ""} pl-4 h-10 `}
            ></img>
          </a>
          <div className="flex items-end hidden h-16 large lg:flex">
            <a
              className={`${window.location.pathname === "/ "
                ? "border-b-4 text-background border-background font-bold"
                : "text-gray-300"
                } transition hover:duration-400 flex items-center py-2 h-16 px-5 text-base font-medium text-gray-300 hover:bg-background hover:text-black`}
              href="#"
            >
              <span>Home</span>
            </a>
            <a
              className={`${window.location.pathname === "/services"
                ? "border-b-4 text-background border-background font-bold"
                : "text-gray-300"
                } transition hover:duration-400 flex items-center py-2 h-16 px-5 text-base font-medium text-gray-300 hover:bg-background hover:text-black`}
              href="#/services"
            >
              <span>Services</span>
            </a>
            <a
              className={`${window.location.pathname === "/about"
                ? "border-b-4 text-background border-background font-bold"
                : "text-gray-300"
                } transition hover:duration-400 flex items-center py-2 h-16 px-5 text-base font-medium text-gray-300 hover:bg-background hover:text-black`}
              href="#/about"
            >
              <span>About </span>
            </a>
            <a
              className={`${window.location.pathname === "trucking/#/apply"
                ? "border-b-4 text-background border-background font-bold"
                : "text-gray-300"
                } transition hover:duration-400 flex items-center py-2 h-16 px-5 text-base font-medium text-gray-300 hover:bg-background hover:text-black`}
              href="#/apply"
            >
              <span>Apply </span>
            </a>
            <a
              className={`${window.location.pathname === "trucking/#/contact"
                ? "border-b-4 text-background border-background font-bold"
                : "text-gray-300"
                } transition hover:duration-400 flex items-center py-2 h-16 px-5 text-base font-medium text-gray-300 hover:bg-background hover:text-black`}
              href="#/contact"
            >
              <span>Contact Us</span>
            </a>
          </div>
          <ul
            className={`mobile ${isOpen ? "block" : "hidden"
              } w-screen justify-end align-end lg:hidden z-40 pt-16 mt-80 items-center`}
          >
            <li>
              <a className="text-right" href="#/">
                <div className="z-40 flex items-center justify-center w-screen h-16 px-5 py-2 text-base font-medium text-center text-black transition border-b-2 border-primary hover:duration-400 bg-background hover:bg-primary hover:text-white">
                  Home
                </div>
              </a>
              <a className="text-center" href="#/services">
                <div className="z-40 flex items-center justify-center h-16 px-5 py-2 text-base font-medium text-center text-black transition border-b-2 border-primary hover:duration-400 bg-background hover:bg-primary hover:text-white">
                  <span>Services </span>
                </div>
              </a>
              <a className="text-center" href="#/about">
                <div className="z-40 flex items-center justify-center h-16 px-5 py-2 text-base font-medium text-center text-black transition ease-in-out border-b-2 border-primary hover:duration-400 bg-background hover:bg-primary hover:text-white">
                  About
                </div>
              </a>
              <a className="text-center" href="#/apply">
                <div className="z-40 flex items-center justify-center h-16 px-5 py-2 text-base font-medium text-center text-black transition ease-in-out border-b-2 border-primary hover:duration-400 bg-background hover:bg-primary hover:text-white">
                  Apply
                </div>
              </a>
              <a className="text-center" href="#/contact">
                <div className="z-40 flex items-center justify-center h-16 px-5 py-2 text-base font-medium text-center text-black transition ease-in-out hover:duration-400 bg-background hover:bg-primary hover:text-white">
                  Contact Us
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div className="absolute z-40 right-1 top-3">
          <button
            type="button"
            className="z-40 cursor-pointer lg:hidden"
            onClick={handleClick}
          >
            <svg
              className="w-16 h-16 fill-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
            >
              {isOpen && (
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              )}
              {!isOpen && (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </nav>
    </Router>
  );
}

export default Nav;
