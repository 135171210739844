import "../output.css";
import LeftFullServiceItem from "./FullServiceItems/LeftFullServiceItem.js";
function ServicesPage() {
  return (
    <div className="flex flex-col items-center pt-10">
      <div class="my-10 text-center">
        <span class="text-[4rem] border-b-4 border-primary text-primary">
          Services
        </span>
      </div>
      <LeftFullServiceItem
        title="Cross Docking"
        id="Service 1"
        details="
        Cross docking helps expedite the shipping process of your product. Instead of going straight from the railway to the warehouse, cross docking allows products to be consolidated at a distributor location, lowering or eliminating storage costs. This also allows products to be sorted more reliably and shipped more efficiently, creating more full truck loads to save on shipping costs.
        "
        imgSrc="https://www.mstruckinginc.com/img/services/crossdocking4.jpg"
      ></LeftFullServiceItem>
      <LeftFullServiceItem
        title="Load Reworking"
        id="Service 2"
        details="Having load issues? We can help. Our load reworking service will allow you to sit back while we reorganize and rectify your shipment. We can deal with overweight loads by unloading and rearranging loads to lower weights. We can even replace your broken pallets, having your shipment back on the road in no time!"
        imgSrc="https://www.txintlfreight.com/wp-content/uploads/2017/02/forklift-loading-freight-shipping-truck.png"
      ></LeftFullServiceItem>
      <LeftFullServiceItem
        title="Short Term Storage"
        id="Service 3"
        details="You may need short-term storage for a variety of reasons. If you've missed a shipping appointment, we can hold your load during rescheduling of the shipment. If a load is shipped ahead of schedule, we can hold it until a truck is available to deliver it. Regardless of the need, we have daily, weekly, and monthly short-term storage available at an affordable cost."
        imgSrc="https://www.warehousingandfulfillment.com/wp-content/uploads/2021/09/pallet-storage-pricing.jpg"
      ></LeftFullServiceItem>
    </div>
  );
}

export default ServicesPage;
