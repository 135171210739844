import "./output.css";
import Services from "./website/ServicesPage.js";
import Home from "./website/Home.js";
import About from "./website/About.js";
import Contact from "./website/Contact.js";
import Apply from "./website/Apply.js";
import Nav from "./website/Nav.js";
import Footer from "./website/Footer.js";
import Bottom from "./website/Bottom";
import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <div className="flex flex-col h-screen overflow-x-hidden">
      <header>
        {window.location.pathname === "/loads" ||
          window.location.pathname === "/dashboard" ? (
          <Nav />
        ) : (
          <Nav />
        )}
      </header>
      <main class="flex-1">
        <Router>
          {/* <Router> */}
          <Routes>
            {/* <Route path="/services" exact element={<Services />}></Route>
            <Route path="/about" exact element={<About />}></Route>
            <Route path="/apply" exact element={<Apply />}></Route>
            <Route path="/contact" exact element={<Contact />}></Route>
            <Route path={`${process.env.WEBSITE_URL}`} element={<Home />}></Route>
            <Route path="/" exact element={<Home />}></Route> */}
            <Route path="/services" exact element={<Services />}></Route>
            <Route path="/about" exact element={<About />}></Route>
            <Route path="/apply" exact element={<Apply />}></Route>
            <Route path="/contact" exact element={<Contact />}></Route>
            <Route path="/" exact element={<Home />}></Route>
            {/* <Route path={`${process.env.WEBSITE_URL}`} element={<Home />}></Route> */}
          </Routes>
        </Router>
      </main>
      <footer>
        {window.location.pathname === "/loads" ||
          window.location.pathname === "/dashboard" ||
          window.location.pathname === "/expenses" ? null : (
          <Footer />
        )}
        {window.location.pathname === "/loads" ||
          window.location.pathname === "/dashboard" ||
          window.location.pathname === "/expenses" ? null : (
          <Bottom />
        )}
      </footer>
    </div>
  );
}

export default App;
