import "../output.css";
function About() {
  return (
    <div>
      <div class="my-10 text-center">
        <span class="text-[4rem] border-b-4 border-primary text-primary">
          About Us
        </span>
      </div>
      <div class="text-center border-b-2 border-gray-300 bg-background">
        <div class="grid grid-cols-1 md:grid-cols-2 justify-items-center">
          <div class="my-auto">
            <span class="text-[3rem] border-b-4 border-primary">
              Our Drivers
            </span>

            <div class="my-8 md:hidden">
              <img
                class="my-auto"
                src="https://hiring-assets.careerbuilder.com/media/attachments/careerbuilder-original-2374.jpg?1465925836"
              ></img>
            </div>

            <div class="w-3/4 mx-auto">
              <p class="py-10 text-lg">
                Our world-class drivers are driven to deliver their shipments promptly and safely. Our drivers know that globalization is driven by the shipping industry, and without reliable shipping, essential products would not be able to reach customers in need. They are aware not only of the essential role they play, but the responsibility they have on the road. We take safety very seriously at Noah Logistics, and our drivers are top-notch at ensuring the safety of everyone on the road as they deliver your shipments.
              </p>
            </div>
          </div>
          <div class="my-8 hidden md:block">
            <img
              class="my-auto"
              src="https://hiring-assets.careerbuilder.com/media/attachments/careerbuilder-original-2374.jpg?1465925836"
            ></img>
          </div>
        </div>
      </div>
      <div class="text-center bg-white border-b-2 border-gray-300">
        <div class="grid grid-cols-1 md:grid-cols-2 justify-items-center">
          <div class="my-8 hidden md:block">
            <img
              class="my-auto"
              src="https://images.ctfassets.net/r6ek15msiuv1/4dJaKNAQljfrBkbmr2neTf/a6f298df47c80de64050cc6ce4da4b72/AdobeStock_294724811_Editorial_Use_Only.jpg"
            ></img>
          </div>
          <div class="my-auto">
            <span class="text-[3rem] border-b-4 border-primary">
              Our Mission
            </span>

            <div class="my-8 md:hidden">
              <img
                class="my-auto"
                src="https://images.ctfassets.net/r6ek15msiuv1/4dJaKNAQljfrBkbmr2neTf/a6f298df47c80de64050cc6ce4da4b72/AdobeStock_294724811_Editorial_Use_Only.jpg"
              ></img>
            </div>

            <div class="w-3/4 mx-auto">
              <p class="py-10 text-lg">
                We realize the importance of our work; we deliver hundreds of loads all around the U.S. each year, bringing products to those who need them. Thus, we are entirely focused on ensuring their prompt and safe delivery. Our mission is to deliver shipments as reliably, fast, and affordably as possible. That means we will work with you, our customer, to ensure that you're entirely satisfied with the shipment and delivery of your product.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="text-center border-b-2 border-gray-300 bg-background">
        <div class="grid grid-cols-1 md:grid-cols-2 justify-items-center">
          <div class="my-auto">
            <span class="text-[3rem] border-b-4 border-primary">
              Our Website Developers
            </span>

            <div class="my-8 md:hidden">
              <img
                class="my-auto"
                src="https://cdn01.alison-static.net/careers/career/web-developer.jpg"
              ></img>
            </div>

            <div class="w-3/4 mx-auto">
              <p class="py-10 text-lg">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent consectetur malesuada ante tincidunt tristique. Integer
                ultricies rhoncus tristique. Mauris et enim eget neque tempus
                pharetra. Sed auctor neque ultricies convallis dapibus. Nulla
                maximus ante risus, vitae tincidunt turpis porttitor elementum.
                In hac habitasse platea dictumst. Nam luctus tempus felis ut
                luctus. Nam tempor ex ac urna consectetur, non vehicula nisi
                mattis. Sed purus ligula, varius vitae mi non, tincidunt
                venenatis elit. Sed ipsum est, consequat dignissim justo quis,
                porttitor rhoncus nisi. Morbi congue justo arcu, ut pharetra
                velit pulvinar lacinia. Nullam fermentum eu lorem id mattis.
                Curabitur a finibus neque.
              </p>
            </div>
          </div>
          <div class="my-8 hidden md:block">
            <img
              class="my-auto"
              src="https://cdn01.alison-static.net/careers/career/web-developer.jpg"
            ></img>
          </div>
        </div>
      </div> */}
      <br />
      <br />
      <br />
    </div>
  );
}

export default About;
