import "../output.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";

function Apply() {
  // Use an API to send job application to
  const form = useRef();
  // Make a new component that will be the error message; conditionally render error based on props
  // If prop csvErr is true, render error message
  const [formSuccess, setFormSuccess] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gljqpfk",
        "template_ya6y7b2",
        form.current,
        "gMYut_B_YEV2ap2Gm"
      )
      .then(
        (result) => {
          console.log("success " + result.text);
          //Create a success message that will be displayed to the user
          //If the email was sent successfully, clear the form

          form.current.reset();
          setFormSuccess(true);
        },
        (error) => {
          console.log("fail " + error.text);
          setFormSuccess(NaN);
        }
      );
  };
  const downloadPDF = (e) => {
    //Download NOAH APPLICATION EDITABLE.pdf from public folder
    fetch("./NoahLogisticsApplication.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "NoahLogisticsApplication.pdf";
        alink.click();
      });
    });
  };

  // return (
  //   <form ref={form} onSubmit={sendEmail}>
  //     <label>Name</label>
  //     <input type="text" name="user_name" />
  //     <label>Email</label>
  //     <input type="email" name="user_email" />
  //     <label>Message</label>
  //     <textarea name="message" />
  //     <input type="submit" value="Send" />
  //   </form>
  // );
  return (
    <div class="mb-10 grid place-items-center sm:grid-cols-1">
      <div class="max-w-3xl">
        <br />
        <div class="flex justify-center">
          <div class="container bg-background rounded-xl p-10 shadow-2xl mb-10">
            <div class="text-center pb-5">
              <span class="text-[3rem] border-b-4 border-primary text-primary">
                Apply
              </span>
            </div>
            <br />
            <form ref={form} onSubmit={sendEmail}>
              <p class="top-info">
                Thank you for your interest in working with us! <br />
                Please fill out the information below, and we'll get in touch
                with you.
              </p>
              <div class="contact-item">
                <div class="item">
                  <p>
                    First Name<span class="required">*</span>
                  </p>
                  <input
                    type="tel"
                    name="first_name"
                    required
                    class="m-0 no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                  />
                </div>
                <div class="item">
                  <p>
                    Last Name<span class="required">*</span>
                  </p>
                  <input
                    type="tel"
                    name="last_name"
                    required
                    class="m-0 no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                  />
                </div>
              </div>
              <div class="contact-item">
                <div class="item">
                  <p>
                    Email<span class="required">*</span>
                  </p>
                  <input
                    type="tel"
                    name="email"
                    required
                    class="m-0 no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                  />
                </div>
                <div class="item">
                  <p>
                    Phone<span class="required">*</span>
                  </p>
                  <input
                    type="tel"
                    name="phone"
                    required
                    class="m-0 no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-gray-500 resize-none"
                  />
                </div>
              </div>
              <div class="w-full">
                <p>
                  Tell us about yourself <span class="required">*</span>
                </p>
                <textarea
                  class=" no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-24 resize-none"
                  name="message"
                  required
                ></textarea>
              </div>
              <div class="btn-block">
                <button
                  class="transition ease-in-out duration-300 
                  rounded bg-primary text-white p-2
                   hover:bg-background hover:text-primary"
                  type="submit"
                  href="/"
                  disabled={formSuccess}
                >
                  Contact Us
                </button>
              </div>
              {/* Success component */}
              {formSuccess ? (
                <div class="text-center">
                  <p class="text-center text-green-500">
                    Message sent successfully!
                  </p>
                </div>
              ) : (
                ""
              )}

              {isNaN(formSuccess) ? (
                <div class="text-center">
                  <p class="text-center text-red-500">
                    There has been an error in sending your message. Please try
                    again. If the problem persists, please contact us directly
                    at{" "}
                    <u>
                      <a href="mailto:noahlogisticsapply@gmail.com">
                        noahlogisticsapply@gmail.com
                      </a>
                    </u>
                    <br />
                  </p>
                </div>
              ) : (
                ""
              )}
              <div class="job-application">
                <p>Job Application:</p>
                <p>
                  You should have gotten an automatic email after getting in
                  contact with us. Now you can fill out your application. You
                  can get it through the download application button below.
                  <br />
                  <br />
                  After you have filled out the application, please send it to
                  noahlogisticsapply@gmail.com as a reply to the email we should
                  have sent you or directly send it to our email.
                </p>
                <br />
                <div class="flex space-x-5 justify-center">
                  <Button
                    type="reset"
                    variant="contained"
                    onClick={downloadPDF}
                  >
                    <DownloadIcon /> Download Application
                  </Button>
                  {/* <input
                    accept="application/pdf"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                      <UploadFileIcon /> Upload Application
                    </Button>
                  </label> */}
                </div>
              </div>
            </form>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apply;
