import "../output.css";
import "../../node_modules/font-awesome/css/font-awesome.min.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  // Use an API to send job application to
  const form = useRef();
  // Make a new component that will be the error message; conditionally render error based on props
  // If prop csvErr is true, render error message
  const [formSuccess, setFormSuccess] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gljqpfk",
        "template_clmo6yi",
        form.current,
        "gMYut_B_YEV2ap2Gm"
      )
      .then(
        (result) => {
          console.log("success " + result.text);
          //Create a success message that will be displayed to the user
          //If the email was sent successfully, clear the form

          form.current.reset();
          setFormSuccess(true);
        },
        (error) => {
          console.log("fail " + error.text);
          setFormSuccess(NaN);
        }
      );
  };
  return (
    <div class="mx-4 mt-10 mb-20 md:flex sm:grid-cols-1 md:space-y-0 space-y-20">
      <div class="md:mr-3 details bg-primary shadow-xl">
        <h3 class="text-2xl font-bold mt-5 pb-2 border-b-2 border-white">
          Contact Us
        </h3>
        <div class="contact-items-wrapper my-auto">
          <div class="grid grid-cols-1 md:flex items-center md:contact-items mt-12 mb-16">
            <i class="fa fa-2x fa-map-marker mx-auto md:pr-2 md:mx-0"></i>
            <p class="text-center md:text-left">
              <strong>Address: </strong>755 Regent Blvd Suite 275 Dallas, TX
              75261
            </p>
          </div>
          <div class="grid grid-cols-1 md:flex md:contact-items items-center mt-12 mb-16">
            <i class="fa fa-2x fa-phone mx-auto md:pr-2 md:mx-0"></i>
            <p class="text-center md:text-left">
              <strong>Phone:</strong> +1-972-900-4989
            </p>
          </div>
          <div class="grid grid-cols-1 md:flex flex-wrap md:contact-items items-center mt-12 mb-16">
            <i class="fa fa-2x fa-envelope mx-auto md:mx-0"></i>
            <p class="text-center md:text-left">
              <strong>Email:</strong>
              <a href="#"> noahlogistic@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
      <form
        class="md:ml-10 bg-background shadow-lg"
        ref={form}
        onSubmit={sendEmail}
      >
        <div class="flex -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 sm:block">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mx-auto"
              for="grid-first-name"
            >
              First Name
              <span class="required">*</span>
            </label>
            <input
              class="rounded-xl appearance-none block w-full bg-white text-gray-700 border mx-auto border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-first-name"
              type="text"
              name="first-name"
              required
            />
          </div>
          <div class="w-full md:w-1/2 px-3 sm:block">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Last Name
              <span class="required">*</span>
            </label>
            <input
              class="rounded-xl appearance-none block w-full bg-white text-gray-700 border mx-auto border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="text"
              name="last-name"
              required
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              E-mail
              <span class="required">*</span>
            </label>
            <input
              class="rounded-xl appearance-none block w-full bg-white text-gray-700 border mx-auto border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="email"
              type="email"
              name="email"
              required
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="rounded-xl block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              Message
              <span class="required">*</span>
            </label>
            <textarea
              class=" no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
              id="message"
              name="message"
              required
            ></textarea>
          </div>
        </div>
        <div>
          {/* Success component */}
          {formSuccess ? (
            <div class="text-center">
              <p class="text-center text-green-500">
                Message sent successfully!
              </p>
              <br />
            </div>
          ) : (
            ""
          )}

          {isNaN(formSuccess) ? (
            <div class="text-center">
              <p class="text-center text-red-500">
                There has been an error in sending your message. Please try
                again. If the problem persists, please contact us directly at{" "}
                <u>
                  <a href="mailto:noahlogisticsapply@gmail.com">
                    noahlogisticsapply@gmail.com
                  </a>
                </u>
                <br />
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div class="flex m-auto items-center">
          <button
            class="transition m-auto align-center shadow bg-primary hover:bg-white hover:text-primary focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
            disabled={formSuccess}
          >
            Send
          </button>
        </div>
        <div class="md:w-2/3"></div>
      </form>
    </div>
  );
}

export default Contact;
