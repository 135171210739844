import "../output.css";
function ServiceCard(props) {
  // transition on apply button
  return (
    <a href={`#/services#${props.title}`}>
      <div class="mb-8 bg-white shadow-lg hover:shadow-xl transform transition duration-500 hover:scale-105 max-w-sm max-h-lg min-h-lg">
        <img class="object-fill w-full h-60" src={props.imagesrc} />
        <div class="px-4 py-2">
          <h1 class="text-xl font-gray-700 font-bold"> {props.title}</h1>
          <p class="text-sm tracking-normal">{props.details}</p>
          <a
            href={`#/services#${props.title}`}
            class="mt-12 w-full text-center bg-primary py-2 rounded-lg block text-white"
          >
            Read more
          </a>
        </div>
      </div>
    </a>
  );
}

export default ServiceCard;
